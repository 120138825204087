import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    Fade,
    Checkbox,
    IconButton
} from '@mui/material';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import StatusChip from "./common/StatusChip"; // Assuming this is a custom component
import { useUser } from "./UserProvider";
import { fetchAccounts } from "../apis/fetchContracts";
import { ACCOUNT_ENDPOINT } from "../interfaces/apiConstants";
import AccountEdit from "./AccountEdit";
import EditIcon from "@mui/icons-material/Edit";
import {useAccount} from "./provider/AccountProvider";

interface AccountRow {
    accountId: number | null;
    accountName: string | null;
    corporateAccount: boolean;
    industry: string | null;
    address: string | null;
    website: string| null;
    email: string | null;
    phoneNumber: string| null;
    faxNumber: string | null;
    numberOfEmployees: string | null;
    yearEstablished: string | null;
    corporateParentId: string | null;
    corporateParentName: string | null;

}

function BusinessDataGrid() {
    const [accounts, setAccounts] = useState<AccountRow[]>([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const { triggerAccountRefresh } = useAccount();
    const { state: { user } } = useUser();
    const [selectedAccount, setSelectedAccount] = useState<AccountRow | null>(null);
    const [isNewAccountDrawerOpen, setIsNewAccountDrawerOpen] = useState(false);
    const [isAccountEditOpen, setIsAccountEditOpen] = useState(false);
    const [newAccountFormValues, setNewAccountFormValues] = useState<AccountRow | undefined>();

    useEffect(() => {
        if (user?.id) {
            setLoading(true);
            fetchAccounts(user.id).then(accounts => {
                const accountRows = accounts.map(account => ({
                    accountId: account.accountId,
                    accountName: account.accountName,
                    corporateAccount: account.corporateAccount ,
                    industry: account.industry,
                    address: account.address,
                    website: account.website,
                    email: account.email,
                    phoneNumber: account.phoneNumber,
                    faxNumber: account.faxNumber,
                    numberOfEmployees: account.numberOfEmployees,
                    yearEstablished: account.yearEstablished,
                    corporateParentId: account.corporateParentId,
                    corporateParentName: account.corporateParentName,
                })).sort((a, b) => a.accountName.localeCompare(b.accountName));
                setAccounts(accountRows);
                setLoading(false);
                console.log("Fetched Accounts")
                console.log(accountRows)
            }).catch(error => {
                console.error("Failed to fetch accounts:", error);
                setLoading(false);
            });
        }
    }, [user?.id]);

    const handleAddAccountClick = () => {
        setNewAccountFormValues({
            accountId: null,
            accountName: null,
            corporateAccount: false,
            industry:  null,
            address:  null,
            website:  null,
            email:  null,
            phoneNumber:  null,
            faxNumber:  null,
            numberOfEmployees:  null,
            yearEstablished:  null,
            corporateParentId: null,
            corporateParentName: null
        });
        setIsNewAccountDrawerOpen(true);
    };

    const handleNewAccountClose = () => {
        setIsNewAccountDrawerOpen(false);
        setNewAccountFormValues(undefined);
    };

    const handleNewAccountSave = (newAccount: AccountRow) => {
        handleSubmit(newAccount);
        handleNewAccountClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getAccountName = (corporateParentId: number | null) => {
        const account = accounts.find(account => account.accountId === corporateParentId);
        return account ? account.accountName : null;
    };
    const handleSubmit = async (accountPayload: any) => {
        accountPayload.clientId = user?.id;
        try {
            const response = await fetch(ACCOUNT_ENDPOINT, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(accountPayload)
            });
            const data = await response.json();
            if (response.ok) {
                const newAccount = {
                    accountId: data.id, // Convert data.id to accountId for the UI
                    accountName: data.accountName,
                    corporateAccount: data.corporateAccount ,
                    industry: data.industry,
                    address: data.address,
                    website: data.website,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    faxNumber: data.faxNumber,
                    numberOfEmployees: data.numberOfEmployees,
                    yearEstablished: data.yearEstablished,
                    corporateParentId: accountPayload.corporateParentId, //fix backend to pass these
                    corporateParentName: getAccountName( accountPayload.corporateParentId )
                };

                console.log("TRIGGER ACCOUNT REFERSH")
                console.log(newAccount)
                triggerAccountRefresh();

                setAccounts(prevAccounts => {
                    const index = prevAccounts.findIndex(account => account.accountId === data.id);
                    if (index !== -1) {
                        const updatedAccounts = [...prevAccounts];
                        updatedAccounts[index] = {...updatedAccounts[index], ...newAccount};
                        return updatedAccounts;
                    } else {
                        return [...prevAccounts, newAccount];
                    }
                });

                setSelectedAccount(prevSelectedAccount => {
                    if (prevSelectedAccount && prevSelectedAccount.accountId === data.id) {
                        return {...prevSelectedAccount, ...newAccount};
                    }
                    return prevSelectedAccount;
                });
                handleClose();
            } else {
                throw new Error(data.message || 'Failed to create account');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleRowClick = (params: { row: React.SetStateAction<AccountRow | null>; }) => {
        setSelectedAccount(params.row);
    };

    const handleEditClick = () => {
        setIsAccountEditOpen(true);
    };

    const corporateAccounts = accounts.filter(account => account.corporateAccount);
    const nonCorporateAccounts = accounts.filter(account => !account.corporateAccount);

    return (
        <Box sx={{height: "90vh", overflowY: "auto"}}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h5">Corporate Accounts</Typography>
                    <Button onClick={handleAddAccountClick} variant="contained" startIcon={<AddTwoToneIcon />}>
                        Add Account
                    </Button>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Fade in={!loading} timeout={400}>
                            <div style={{ height: 400, marginTop: '16px' }}>
                                <DataGrid
                                    disableColumnMenu
                                    rows={corporateAccounts}
                                    columns={[
                                        { field: 'accountId', headerName: 'Account ID',  flex: 1, minWidth: 200, headerAlign: 'center',  align: 'center' },
                                        { field: 'accountName', headerName: 'Account Name',  flex: 1, minWidth: 250, headerAlign: 'center',  align: 'center' },
                                        {
                                            field: 'corporateAccount',
                                            headerName: 'Corporate Account',
                                            flex: 1,
                                            minWidth: 250,
                                            renderCell: (params) => (
                                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    <Checkbox   sx={{
                                                        '&.Mui-disabled': {
                                                            color: "#fc6a8a",  // maintain color even when disabled
                                                        },
                                                    }} checked={params.value} disabled />
                                                </Box>
                                            )
                                            , headerAlign: 'center'
                                        },
                                    ]}
                                    getRowId={(row) => row.accountId ? row.accountId : 11 }
                                    loading={loading}
                                    onRowClick={handleRowClick}
                                    sx={{
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                        },
                                        '.MuiDataGrid-cell:focus': {
                                            outline: 'none',
                                        },
                                        '.MuiDataGrid-cell:focus-within': {
                                            outline: 'none',
                                        },
                                        height: 400, // Ensures the DataGrid has enough space
                                    }}
                                />
                            </div>
                        </Fade>
                        <Typography variant="h5" style={{ marginTop: '20px' }}>Subsidiary Accounts</Typography>
                        <Fade in={!loading} timeout={400}>
                            <div style={{ height: 400, marginTop: '16px' }}>
                                <DataGrid
                                    disableColumnMenu
                                    rows={nonCorporateAccounts}
                                    columns={[
                                        { field: 'accountId', headerName: 'Account ID',  flex: 1, minWidth: 200, headerAlign: 'center',  align: 'center' },
                                        { field: 'accountName', headerName: 'Account Name',  flex: 1, minWidth: 250, headerAlign: 'center',  align: 'center' },
                                        {
                                            field: 'corporateAccount',
                                            headerName: 'Corporate Account',
                                            flex: 1,
                                            minWidth: 250,
                                            renderCell: (params) => (
                                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    <Checkbox   sx={{
                                                        '&.Mui-disabled': {
                                                            color: "#fc6a8a",  // maintain color even when disabled
                                                        },
                                                    }} checked={params.value} disabled />
                                                </Box>
                                            )
                                            , headerAlign: 'center'
                                        },
                                    ]}
                                    getRowId={(row) => row.accountId ? row.accountId : 11 }
                                    loading={loading}
                                    onRowClick={handleRowClick}
                                    sx={{
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                        },
                                        '.MuiDataGrid-cell:focus': {
                                            outline: 'none',
                                        },
                                        '.MuiDataGrid-cell:focus-within': {
                                            outline: 'none',
                                        },
                                        height: 400, // Ensures the DataGrid has enough space
                                    }}
                                />
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ position: 'sticky', top: 16, padding: 2, margin: '16px', height: 'fit-content' }}>
                            {selectedAccount && (
                                <>
                                    <Typography
                                        variant="body1"
                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                    >
                                        <strong>Account Name:</strong>
                                        <StatusChip status={selectedAccount?.accountName || ""} sx={{ marginLeft: 2 }} />
                                        <IconButton onClick={handleEditClick} size="small" color="primary">
                                            <EditIcon />
                                        </IconButton>
                                        <Box sx={{ marginLeft: 1 }}>
                                            <AccountEdit
                                                account={selectedAccount}
                                                corporateAccounts={accounts.filter(account => account.corporateAccount)}
                                                onSave={handleSubmit}
                                                onClose={() => {
                                                    setIsAccountEditOpen(false);
                                                }}
                                                openDrawer={isAccountEditOpen} // Pass the openDrawer prop
                                            />
                                        </Box>
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                    >
                                        <strong>Corporate Account:</strong>
                                        <StatusChip status={selectedAccount.corporateAccount ? "Yes": "No"} sx={{ marginLeft: 2 }} />
                                    </Typography>
                                    {selectedAccount.corporateAccount === false  && (<div>
                                        <Typography
                                            variant="body1"
                                            sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                        >
                                            <strong>Parent Corporate Account:</strong>
                                            <StatusChip status={selectedAccount.corporateParentName ? selectedAccount.corporateParentName : ""} sx={{ marginLeft: 2 }} />
                                        </Typography>
                                    </div>)}
                                    <Typography
                                        variant="body1"
                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                    >
                                        <strong>Type of Business:</strong>
                                        <StatusChip status={selectedAccount.industry ? selectedAccount.industry : ""} sx={{ marginLeft: 2 }} />
                                    </Typography>
                                    { selectedAccount.corporateAccount && (<div>
                                        <Typography
                                            variant="body1"
                                            sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                        >
                                            <strong>Number of Employees:</strong>
                                            <StatusChip status={selectedAccount.numberOfEmployees ? selectedAccount.numberOfEmployees : ""} sx={{ marginLeft: 2 }} />
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                        >
                                            <strong>Year Established:</strong>
                                            <StatusChip status={selectedAccount.yearEstablished ? selectedAccount.yearEstablished : ""} sx={{ marginLeft: 2 }} />
                                        </Typography>
                                    </div>)}

                                    <Typography
                                        variant="body1"
                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                    >
                                        <strong>Address:</strong>
                                        <StatusChip status={selectedAccount.address ? selectedAccount.address : ""} sx={{ marginLeft: 2 }} />
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                    >
                                        <strong>Email:</strong>
                                        <StatusChip status={selectedAccount.email ? selectedAccount.email : ""} sx={{ marginLeft: 2 }} />
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                    >
                                        <strong>Phone Number:</strong>
                                        <StatusChip status={selectedAccount?.phoneNumber ? selectedAccount.phoneNumber : ""} sx={{ marginLeft: 2 }} />
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                                    >
                                        <strong>Fax Number:</strong>
                                        <StatusChip status={selectedAccount.faxNumber ? selectedAccount.faxNumber : ""} sx={{ marginLeft: 2 }} />
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>

            {isNewAccountDrawerOpen && (

                <AccountEdit
                    account={newAccountFormValues}
                    corporateAccounts={accounts.filter(account => account.corporateAccount)}
                    onSave={handleNewAccountSave}
                    onClose={handleNewAccountClose}
                    openDrawer={isNewAccountDrawerOpen}
                />
            )}
        </Box>
    );
}

export default BusinessDataGrid;
