import React, { useState } from 'react';
import {Box, Button, MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";

type ResponsiveDatePickersProps = {
    label: string;
    goBack: () => void;
    onDataChange: (newValue: string | null) => void;
    currentValue: string | null;
    selections: Array<{ value: string, label: string }>;
};

export default function InputSelect({ label, goBack, onDataChange, currentValue, selections }: ResponsiveDatePickersProps) {
    const [tempData, setTempData] = useState<string | null>(currentValue);

    // Update the local temporary data value
    const handleDataChange = (event: SelectChangeEvent<string>) => {
        setTempData(event.target.value as string);
    };

    // Call the onDataChange callback with the temporary data value when "Save" is clicked
    const handleSave = () => {
        console.log("DOOOGGGGGGG")
        console.log(tempData)
        if(tempData===undefined){
            onDataChange(null);
        }else{
            onDataChange(tempData);

        }
        goBack();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh'
            }}
        >
        <Stack spacing={2} alignItems="center">
            <Typography variant="h5">{label}</Typography>

            <Select
                style={{ minWidth: '200px' }}
                value={tempData ? tempData : undefined}
                onChange={handleDataChange}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
            >
                {selections.map((item) => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>

            <Button style={{ width: '50%' }} variant="contained" onClick={handleSave}>Save</Button>
            <Button style={{ width: '50%' }} onClick={goBack}>Cancel</Button>
        </Stack>
        </Box>
    );
}
