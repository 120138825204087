import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Drawer,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Checkbox,
    IconButton
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const businessTypes = ["Multi-family", "Hospitality", "Nursing Home", "Healthcare Facility", "Retail Complex", "Office Building", "Industrial Facility", "Restaurant", "School", "Government Building"];

interface AccountRow {
    accountId: number | null;
    accountName: string | null;
    corporateAccount: boolean;
    industry: string | null;
    address: string | null;
    website: string | null;
    email: string | null;
    phoneNumber: string | null;
    faxNumber: string | null;
    numberOfEmployees: string | null;
    yearEstablished: string | null;
    corporateParentId: string | null;
    corporateParentName: string | null;
}

interface FormErrors {
    accountName?: string;
    corporateAccount?: boolean;
    typeOfBusiness?: string;
    numberOfEmployees?: string;
    yearEstablished?: string;
    address?: string;
    email?: string;
    phoneNumber?: string;
    faxNumber?: string;
}

interface AccountEditProps {
    account?: AccountRow;
    onSave: (updatedAccount: AccountRow) => void;
    onClose: () => void;
    openDrawer: boolean; // Add this line
    corporateAccounts: AccountRow[]
}

const AccountEdit: React.FC<AccountEditProps> = ({ account, onSave, onClose, openDrawer, corporateAccounts }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [formValues, setFormValues] = useState<AccountRow>(account || {
        accountId: null,
        accountName: null,
        corporateAccount: false,
        industry: null,
        address: null,
        website: null,
        email: null,
        phoneNumber: null,
        faxNumber: null,
        numberOfEmployees: null,
        yearEstablished: null,
        corporateParentId: null,
        corporateParentName: null
    });
    const [errors, setErrors] = useState<FormErrors>({});

    useEffect(() => {
        if (account) {
            setFormValues(account);
        }
    }, [account]);

    useEffect(() => {
        setDrawerOpen(openDrawer);
    }, [openDrawer]);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        onClose(); // Call onClose when drawer is closed
    };

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target as HTMLInputElement;
        let newValue = type === 'checkbox' ? checked : value;

        if (name === "phoneNumber") {
            // Format phone number
            newValue = formatPhoneNumber(newValue.toString());
        }

        setFormValues({
            ...formValues,
            [name!]: newValue
        });
    };

    const formatPhoneNumber = (phoneNumber: string) => {
        // Remove all non-digit characters
        const cleaned = phoneNumber.replace(/\D/g, '');

        // Format the cleaned phone number as (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (match) {
            return `${match[1] ? `(${match[1]}` : ''}${match[2] ? `) ${match[2]}` : ''}${match[3] ? `-${match[3]}` : ''}`;
        }

        return phoneNumber;
    };

    const validate = (): boolean => {
        let tempErrors: FormErrors = {};
        if (!formValues.accountName) tempErrors.accountName = "This field is required.";
        if (!formValues.industry) tempErrors.typeOfBusiness = "This field is required.";
        if (formValues.industry === "Finance" && !formValues.numberOfEmployees) {
            tempErrors.numberOfEmployees = "Number of employees is required for Finance business type.";
        }
        if (!formValues.email) {
            tempErrors.email = "This field is required.";
        } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            tempErrors.email = "Email is not valid.";
        }
        if (!formValues.phoneNumber) {
            tempErrors.phoneNumber = "This field is required.";
        } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formValues.phoneNumber)) {
            tempErrors.phoneNumber = "Phone number must be in the format (XXX) XXX-XXXX.";
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (validate()) {
            console.log(formValues)
            onSave(formValues);
            handleDrawerClose();
        }
    };

    const color = "rgba(255,255,255,0)";

    return (
        <Box sx={{}}>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{
                    sx: { '& .MuiBackdrop-root': { backgroundColor: color } }
                }}
                PaperProps={{
                    sx: {
                        mt: 6,
                        borderTopLeftRadius: '16px',
                        height: 'calc(100% - 20px)', // Adjust height to accommodate the margin
                    }
                }}
            >
                <Box sx={{ width: 600, pt: 4, px: 6, position: 'relative', height: "80vh", overflowY: "auto" }}>
                    <IconButton
                        onClick={handleDrawerClose}
                        size="small"
                        color="secondary"
                        sx={{ position: 'absolute', top: 16, right: 16 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                        {account?.accountId ? 'Edit Account Details' : 'Add New Account'}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Account Name"
                            name="accountName"
                            fullWidth
                            margin="normal"
                            value={formValues.accountName}
                            onChange={handleChange}
                            error={!!errors.accountName}
                            helperText={errors.accountName}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formValues.corporateAccount}
                                    onChange={handleChange}
                                    name="corporateAccount"

                                />
                            }
                            label="Corporate Account"
                        />
                        {!formValues.corporateAccount && (
                            <FormControl fullWidth margin="normal" error={!!errors.typeOfBusiness}>
                                <InputLabel>Parent Corporate Account</InputLabel>
                                <Select
                                    name="corporateParentId"
                                    value={formValues.corporateParentId}
                                    onChange={handleChange}
                                    label="Parent Corporate Account"
                                >
                                    {corporateAccounts.map((account) => (
                                        <MenuItem key={account.accountId} value={account.accountId ? account.accountId : "asdf"}>
                                            {account.accountName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.typeOfBusiness && <Typography color="error">{errors.typeOfBusiness}</Typography>}
                            </FormControl>
                        )}
                        <FormControl fullWidth margin="normal" error={!!errors.typeOfBusiness}>
                            <InputLabel>Type of Business</InputLabel>
                            <Select
                                name="industry"
                                value={formValues.industry}
                                onChange={handleChange}
                                label="Type of business"
                            >
                                {businessTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.typeOfBusiness && <Typography color="error">{errors.typeOfBusiness}</Typography>}
                        </FormControl>
                        {formValues.corporateAccount && (
                            <>
                                <TextField
                                    label="Number of Employees"
                                    name="numberOfEmployees"
                                    fullWidth
                                    margin="normal"
                                    value={formValues.numberOfEmployees}
                                    onChange={handleChange}
                                    error={!!errors.numberOfEmployees}
                                    helperText={errors.numberOfEmployees}
                                    type="number"
                                />
                                <TextField
                                    label="Year Established"
                                    name="yearEstablished"
                                    fullWidth
                                    margin="normal"
                                    value={formValues.yearEstablished}
                                    onChange={handleChange}
                                    type="number"
                                    InputProps={{ inputProps: { min: 1900, max: new Date().getFullYear() } }}
                                />
                            </>
                        )}
                        <TextField
                            label="Address"
                            name="address"
                            fullWidth
                            margin="normal"
                            value={formValues.address}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            fullWidth
                            margin="normal"
                            value={formValues.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                        <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            fullWidth
                            margin="normal"
                            value={formValues.phoneNumber}
                            onChange={handleChange}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber}
                            placeholder="(XXX) XXX-XXXX"
                        />
                        <TextField
                            label="Fax Number"
                            name="faxNumber"
                            fullWidth
                            margin="normal"
                            value={formValues.faxNumber}
                            onChange={handleChange}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Drawer>
        </Box>
    );
};

export default AccountEdit;
