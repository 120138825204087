import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Button, InputAdornment, Stack, Typography} from "@mui/material";
import {useState} from "react";
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";


type ResponsiveDatePickersProps = {
    label: string;
    goBack: () => void;
    onDataChange: (string: any) => void;
    originalValue: string | null;
    startText: string
    commas: boolean
};


export default function BasicTextFields({ label, goBack, onDataChange, originalValue, startText, commas  }: ResponsiveDatePickersProps) {

    const [currentValue, setCurrentValue] = useState<any>(originalValue);
    // Update the local temporary date value
    const handleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setCurrentValue(event.target.value);
        const { value } = event.target;
        const numericValue = value.replace(/,/g, ''); // Remove existing commas
        if (!isNaN(Number(numericValue)) && commas) {
            setCurrentValue(formatNumber(numericValue));
        }else {
            setCurrentValue(value);
        }
    };

    // Call the onDateChange callback with the temporary date value when "Save" is clicked
    const handleSave = () => {
        console.log(currentValue + " Inside Text Fields")
        onDataChange(currentValue);
        goBack()
    };

    const [isModalOpen, setIsModalOpen] = useState(false);


    // Function to call when "Cancel" is clicked
    const handleCancel = () => {
        if (currentValue !== originalValue) {
            setIsModalOpen(true); // Show confirmation if there are unsaved changes
        } else {
            goBack()
        }
    };

    // Confirm cancellation despite unsaved changes
    const handleConfirmCancellation = () => {
        setIsModalOpen(false);
        // Handle the confirmation of cancellation here
        goBack()

    };

    // Stay on the current page to save changes
    const handleCancelCancellation = () => {
        setIsModalOpen(false);
        // Handle the event when the user decides to stay on the page
    };



    function formatNumber(value: string) {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh' // This assumes you want to take the full viewport height
            }}
        >

    <Stack spacing={2} alignItems="center">

        <Typography variant="h5">{label}</Typography>
        <TextField id="outlined-basic"  variant="outlined"  value={currentValue}  onChange={handleDataChange}

           InputProps={{
               startAdornment: <InputAdornment position="start">{startText}</InputAdornment>,
           }}
             sx={{ width: '300px' }}
        />

        <Button style={{ width: '50%' }} variant="contained" onClick={handleSave}>Save</Button>
        <Button style={{ width: '50%' }} onClick={handleCancel}>Cancel</Button>
    </Stack>

            <UnsavedChangesConfirmationModal
                open={isModalOpen}
                onConfirm={handleConfirmCancellation}
                onCancel={handleCancelCancellation}
                message={"You have unsaved changes. Are you sure you want to cancel?"}
                confirmMessage={"YES, CANCEL"}

            />
        </Box>
    );
}
