import React, { useState, useEffect } from 'react';
import { Box, Button, Autocomplete, TextField, Stack, Typography, Checkbox, FormControlLabel } from "@mui/material";
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";
import { valueToLabel } from "../utils/valueToLabel";
import {isCustomServiceType, serviceTypes} from "../constants/serviceTyps";

type ResponsiveDatePickersProps = {
    label: string;
    goBack: () => void;
    onDataChange: (newValue: string | null) => void;
    currentValue: string | null;
    selections: Array<{ value: string, label: string }>;
};

export default function InputAutocomplete({ label, goBack, onDataChange, currentValue, selections }: ResponsiveDatePickersProps) {

    const [isCustomService, setIsCustomService] = useState<boolean>(isCustomServiceType(currentValue));
    const [standardServiceType, setStandardServiceType] = useState<string | null>(currentValue && !isCustomServiceType(currentValue) ? valueToLabel(currentValue, serviceTypes) : '' );
    const [customServiceType, setCustomServiceType] = useState(isCustomServiceType(currentValue) ? currentValue  : '');

    // Update the local temporary data value
    const handleDataChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
        setStandardServiceType(newValue);
    };

    const handleCustomServiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomServiceType(event.target.value);
    };

    const handleCustomServiceCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCustomService(event.target.checked);
        if (!event.target.checked) {
            setCustomServiceType('');
        }
    };

    // Call the onDataChange callback with the temporary data value when "Save" is clicked
    const handleSave = () => {
        if (isCustomService) {
            onDataChange(customServiceType);
        } else {
            const selectedItem = selections.find(x => x.label === standardServiceType);
            if (selectedItem) {
                onDataChange(selectedItem.value);
            } else {
                onDataChange(null);
            }
        }
        goBack();
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    // Confirm cancellation despite unsaved changes
    const handleConfirmCancellation = () => {
        setIsModalOpen(false);
        goBack();
    };

    // Stay on the current page to save changes
    const handleCancelCancellation = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        const label = currentValue ? valueToLabel(currentValue, serviceTypes) : null;
        if (label !== standardServiceType || (isCustomService && customServiceType !== '')) {
            setIsModalOpen(true);
        } else {
            goBack();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh'
            }}
        >
            <Stack spacing={2} alignItems="center">
                <Typography variant="h5">{label}</Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCustomService}
                            onChange={handleCustomServiceCheckboxChange}
                        />
                    }
                    label="Custom Service Type"
                />
                {!isCustomService ? (
                    <Autocomplete
                        style={{ minWidth: '300px' }}
                        value={standardServiceType}
                        onChange={handleDataChange}
                        options={selections.map((option) => option.label)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                ) : (
                    <TextField
                        style={{ minWidth: '300px' }}
                        value={customServiceType}
                        onChange={handleCustomServiceChange}
                        placeholder="Enter custom service type"
                    />
                )}

                <Button style={{ width: '50%' }} variant="contained" onClick={handleSave}>Save</Button>
                <Button style={{ width: '50%' }} onClick={handleCancel}>Cancel</Button>
            </Stack>
            <UnsavedChangesConfirmationModal
                open={isModalOpen}
                onConfirm={handleConfirmCancellation}
                onCancel={handleCancelCancellation}
                message={"You have unsaved changes. Are you sure you want to cancel?"}
                confirmMessage={"YES, CANCEL"}
            />
        </Box>
    );
}
