import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";

type InputWithCheckboxesProps = {
    label: string;
    goBack: () => void;
    onDataChange: (newValue: {
        term: string | null,
        //renewalDate: string | null
    }) => void;
    currentValue: {
        term: string | null,
        //renewalDate: string | null
    } | null;
};

export default function TermInputNumber({
                                            label,
                                            goBack,
                                            onDataChange,
                                            currentValue,
                                        }: InputWithCheckboxesProps) {
    const [period, setPeriod] = useState({ daily: false, monthly: false, yearly: false });
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [isRenewalTermsNA, setIsRenewalTermsNA] = useState( currentValue?.term === "NA"); // New state for checkbox

    useEffect(() => {
        if (currentValue?.term) {
            const periodData = currentValue.term.split("_")[1];
            setPeriodOnStart(periodData);
            setValue(currentValue.term.split("_")[0]);
        }
    }, [currentValue]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setPeriod({ daily: false, monthly: false, yearly: false, [name]: checked });
    };

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        validateValue(newValue);
        if (!error) {
            setValue(newValue);
        }
    };

    const validateValue = (newValue: string) => {
        const number = parseInt(newValue, 10);
        if (period.daily && (number < 1 || number > 1000)) {
            setError('Please enter a value between 1 and 1000 for days.');
        } else if (period.monthly && (number < 1 || number > 12)) {
            setError('Please enter a value between 1 and 12 for months.');
        } else if (period.yearly && (number < 1 || number > 100)) {
            setError('Please enter a value between 1 and 100 for years.');
        } else {
            setError('');
        }
    };

    const handleSave = () => {
        if (!error) {
            onDataChange({
                term: isRenewalTermsNA ? "NA": `${value}_${getPeriodForData(getPeriodLabel())}`
                //renewalDate: isRenewalDateDifferent && renewalDate ? renewalDate.format("YYYY-MM-DD") : null
            });
            goBack();
        }
    };

    const setPeriodOnStart = (periodData: string) => {
        if ('DAYS' === periodData) {
            setPeriod({ daily: true, monthly: false, yearly: false });
        }
        if ('MONTHS' === periodData) {
            setPeriod({ daily: false, monthly: true, yearly: false });
        }
        if ('YEARS' === periodData) {
            setPeriod({ daily: false, monthly: false, yearly: true });
        }
    };

    const getPeriodForData = (periodLabel: string) => {
        if ('day(s)' === periodLabel) return 'DAYS';
        if ('month(s)' === periodLabel) return 'MONTHS';
        if ('year(s)' === periodLabel) return 'YEARS';
        return '';
    };

    const getPeriodLabel = () => {
        if (period.daily) return 'day(s)';
        if (period.monthly) return 'month(s)';
        if (period.yearly) return 'year(s)';
        return '';
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleConfirmCancellation = () => {
        setIsModalOpen(false);
        goBack();
    };

    const handleCancelCancellation = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        goBack();
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh'
            }}>
                <Stack spacing={2} alignItems="center">
                    <Typography variant="h5">{label}</Typography>

                    {/* Renewal Terms Not Applicable Checkbox */}
                    <FormControlLabel
                        control={<Checkbox checked={isRenewalTermsNA} onChange={(e) => setIsRenewalTermsNA(e.target.checked)} />}
                        label="Renewal terms is not applicable"
                    />

                    {!isRenewalTermsNA && (
                        <>
                            <Box>
                                <FormControlLabel control={<Checkbox checked={period.daily} onChange={handleCheckboxChange} name="daily" />} label="Daily" />
                                <FormControlLabel control={<Checkbox checked={period.monthly} onChange={handleCheckboxChange} name="monthly" />} label="Monthly" />
                                <FormControlLabel control={<Checkbox checked={period.yearly} onChange={handleCheckboxChange} name="yearly" />} label="Yearly" />
                            </Box>

                            <TextField
                                error={!!error}
                                helperText={error}
                                type="number"
                                value={value}
                                onChange={handleValueChange}
                                label={`Enter number of ${getPeriodLabel()}`}
                                sx={{ minWidth: '200px' }}
                            />
                        </>
                    )}

                    <Button variant="contained" onClick={handleSave} disabled={!!error}>Save</Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                </Stack>
                <UnsavedChangesConfirmationModal
                    open={isModalOpen}
                    onConfirm={handleConfirmCancellation}
                    onCancel={handleCancelCancellation}
                    message={"You have unsaved changes. Are you sure you want to cancel?"}
                    confirmMessage={"YES, CANCEL"}
                />
            </Box>
        </LocalizationProvider>
    );
}
