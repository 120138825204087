import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Button, Stack, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";

type ResponsiveDatePickersProps = {
    label: string;
    goBack: () => void;
    onDateChange: (newDate: any, isApplicable: boolean | null) => void;
    currentValue: dayjs.Dayjs | null;
    isApplicable: boolean | null;  // New prop for isApplicable
};

export default function ResponsiveDatePickers({ label, goBack, onDateChange, currentValue, isApplicable }: ResponsiveDatePickersProps) {
    // Local state to hold the temporary date value and the checkbox state
    const [tempDate, setTempDate] = useState<dayjs.Dayjs | null>(currentValue);
    const [localIsApplicable, setLocalIsApplicable] = useState<boolean | null>( isApplicable );

    // Update the local temporary date value
    const handleDateValueChange = (newValue: any) => {
        setLocalIsApplicable(true);
        setTempDate(newValue);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setLocalIsApplicable(!checked);
        if (checked) {
            //****************** LETS NOT MAKE DATE FIELD UPDATED BASED ON APPLICABLE THEY SHOULD BE INDEPENDENT
            //setTempDate(null); //
        }
    };

    // Call the onDateChange callback with the temporary date value and isApplicable when "Save" is clicked
    //****************** LETS NOT MAKE DATE FIELD UPDATED BASED ON APPLICABLE THEY SHOULD BE INDEPENDENT
    const handleSave = () =>
    {
        if (localIsApplicable == null){
            onDateChange(tempDate, null);
        }else if (localIsApplicable === false || !dayjs(tempDate).isValid()) {
            //put some validation here
            onDateChange(tempDate, false); // Pass null and false if "not applicable" or invalid date
        } else {
            onDateChange(tempDate, true);
        }
        goBack();
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    // Confirm cancellation despite unsaved changes
    const handleConfirmCancellation = () => {
        setIsModalOpen(false);
        goBack();
    };

    // Stay on the current page to save changes
    const handleCancelCancellation = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        if (currentValue !== tempDate || isApplicable !== (isApplicable === false)) {
            setIsModalOpen(true); // Show confirmation if there are unsaved changes
        } else {
            goBack();
        }
    };

    const isChecked = (isApplicable : boolean | null) => {
        if( isApplicable === null){
            return false;
        } else {
            return !isApplicable;
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '90vh' // This assumes you want to take the full viewport height
                }}
            >
                <Stack spacing={2} alignItems="center">
                    <Typography variant="h5">{label}</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isChecked(localIsApplicable)}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label={`${label} is not applicable`}
                    />
                    <DatePicker
                        value={tempDate} // Use the local state for the value
                        onChange={handleDateValueChange} // Update the local state when the date changes
                        disabled={isChecked(localIsApplicable)} // Disable the picker if "not applicable" is checked
                    />
                    <Button style={{ width: '50%' }} variant="contained" onClick={handleSave}>Save</Button>
                    <Button style={{ width: '50%' }} onClick={handleCancel}>Cancel</Button>
                </Stack>

                <UnsavedChangesConfirmationModal
                    open={isModalOpen}
                    onConfirm={handleConfirmCancellation}
                    onCancel={handleCancelCancellation}
                    message={"You have unsaved changes. Are you sure you want to cancel?"}
                    confirmMessage={"YES, CANCEL"}
                />
            </Box>
        </LocalizationProvider>
    );
}
