import React, { useState, ChangeEvent, useRef } from 'react';
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Grid,
    TablePagination,
    Accordion,
    AccordionActions,
    Stack,
    Container
} from '@mui/material';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {ScopeItem} from "../interfaces/scopeOfWork";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";
interface ScopeOfWorkPageProps {
    goBack: () => void;
    onDataChange: (scopeItems: ScopeItem) => void;
    currentValue: ScopeItem | null;
}

const ScopeOfWorkPage: React.FC<ScopeOfWorkPageProps> = ({
                                                             goBack,
                                                             onDataChange,
                                                             currentValue,
                                                         }: ScopeOfWorkPageProps) => {
    const [scopeItems, setScopeItems] = useState<ScopeItem>(currentValue ? currentValue : {
        projectOverview: "",
        scopeOfWork: "",
        talentRequirements: "" // Optional property for a tag
    });
    const handleSubmit = () => {
        onDataChange(scopeItems)
        goBack()
    };

    const handleTextChange = (field: keyof ScopeItem, newValue: string) => {
        setScopeItems((prevState) => ({
            ...prevState,
            [field]: newValue
        }));
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    // Confirm cancellation despite unsaved changes
    const handleConfirmCancellation = () => {
        setIsModalOpen(false);
        // Handle the confirmation of cancellation here
        goBack()

    };

    // Stay on the current page to save changes
    const handleCancelCancellation = () => {
        setIsModalOpen(false);
        // Handle the event when the user decides to stay on the page
    };

    const handleCancel = () =>{
        if (currentValue !== scopeItems) {
            setIsModalOpen(true); // Show confirmation if there are unsaved changes
        } else {
            goBack()
        }
    }


    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
                height: '90vh',// Full view height
            }
            }
        >
            <br/>
            <Stack spacing={2} alignItems="center">
                <Typography variant="h5" sx={{ paddingLeft: '5px' }}>Scope of Work</Typography>

                <TextareaAutosize
                    minRows={8}
                    style={{ width: '600px' }} // Make sure the textarea takes up the full cell width
                    value={scopeItems.scopeOfWork}
                    onChange={(event) => {
                        handleTextChange("scopeOfWork", event.target.value);
                    }}
                />


                <Button style={{ width: '20%' }} variant="contained" onClick={handleSubmit}>Save</Button>
                <Button style={{ width: '20%' }} onClick={handleCancel}>Cancel</Button>
            </Stack>
            <UnsavedChangesConfirmationModal
                open={isModalOpen}
                onConfirm={handleConfirmCancellation}
                onCancel={handleCancelCancellation}
                message={"You have unsaved changes. Are you sure you want to cancel?"}
                confirmMessage={"YES, CANCEL"}

            />
        </Box>
    );
};

export default ScopeOfWorkPage;
